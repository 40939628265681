<template>

  <div class="cta_main_view w-100">

    <div class="view_inner d-flex flex-column">

      <div class="view_center basic_form">
        <div class="steps_dot mb-3">
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle" v-if="getCampaignAdd.campaign_type.retargeting"></span>
        </div>
        <div class="rules_set">
          <div class="input_field col-md-12 nopad-lr">
            <div class="heading_top clear d-flex align-items-center">
              <label class="pull-left">After 'X' second <span style="color: #1f216e" v-if="isBridgeCTACampaign()">(Max Seconds = 30)</span>.</label>

              <div class="radio_input_image ml-auto">
                <input value="time" v-model="getCampaignAdd.trigger_type" type="radio"
                       name="rules"
                       id="Xsecond" data-cy="on-sec">
                <label for="Xsecond" class="no_text"></label>
              </div>

            </div>
            <div class="inner_fields" v-if="getCampaignAdd.trigger_type === 'time'">
              <div class="input_field clear">
                <label>Second</label>
                <input v-model="getCampaignAdd.trigger_seconds" min="1" type="number" data-cy="x-sec">
              </div>

            </div>
          </div>

          <div v-if="!isBridgeCTACampaign()" class="input_field col-md-12 nopad-lr">
            <div class="heading_top clear  d-flex align-items-center">
              <label class="pull-left">On Exit-intent</label>
              <div class="radio_input_image ml-auto">
                <input value="exit_intent" v-model="getCampaignAdd.trigger_type" type="radio"
                       name="rules"
                       id="exitintent" data-cy="on-exit">
                <label for="exitintent" class="no_text"></label>
              </div>

            </div>

          </div>

          <!--<div class="input_field col-md-12 nopad-lr">
              <div class="heading_top clear">
                  <label class="pull-left">After scrolling 'X' amount.</label>

                  <div class="radio pull-right">
                      <input value="scroll" v-model="ruleCheck" type="radio"
                             name="rules"
                             id="Xscrolling">
                      <label for="Xscrolling"></label>
                  </div>
              </div>
              <div class="inner_fields" v-if="ruleCheck == 'scroll'">
                  <div class="input_field clear">
                      <div class="input_field clear">
                          <div class="col-md-6 nopad-l">
                              <label>Amount</label>
                              <input type="number" v-model="getCallToActionSelection.savingDetails.trigger_scroll_value">
                          </div>
                          <div class="col-md-6 nopad-e">
                              <label>Unit</label>
                              <select name="" id="" v-model="getCallToActionSelection.savingDetails.trigger_scroll_type">
                                  <option value="px">Px</option>
                                  <option value="percentage">Percent</option>
                              </select>
                          </div>
                      </div>
                  </div>

              </div>
          </div>-->
        </div>

        <!--<div class=" mb-3 input_icon_text d-flex align-items-center ">-->
        <!--<div class="content">-->
        <!--<div class="url">Theme</div>-->
        <!--<div class="name">-->
        <!--<div class="validation_input">-->
        <!---->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

      </div>

      <div class="view_bottom">

        <div class="view_bottom_inner text-center">
          <template v-if="getCampaignLoaders.store">
            <button :disabled="getCampaignLoaders.store"
                    class="btn---cta btn-blue btn-round d-inline-flex btn--loader loader--rightr">
              <span>{{ getCampaignButtonText() }}</span>
              <clip-loader class="ml-1" v-if="getCampaignLoaders.store" :size="size" :color="color"></clip-loader>
            </button>
          </template>
          <template v-else>
            <!--                        <button v-if="getCampaignAdd.campaign_type.retargeting"-->
            <!--                                @click="$router.push({ 'name': 'retargeting' })"-->
            <!--                                class="btn-&#45;&#45;cta  light-blue btn-round">-->
            <!--                            <span>Go Back</span>-->
            <!--                        </button>-->

            <button @click="$router.push({ 'name': 'customize' })"
                    class="btn---cta  light-blue btn-round">
              <span>Go Back</span>
            </button>

            <!-- Added next button here to move on page retargeting case (retarget & cta) to use retarget in last -->
            <!-- if type is of retargeting -->
            <button @click="$router.push({ 'name': 'retargeting' })"
                    v-if="getCampaignAdd.campaign_type.retargeting"
                    class="btn---cta btn-blue btn-round">
              <span>Next</span>
            </button>

            <button v-else @click.prevent="handleTriggersCampaign()"
                    class="btn---cta d-inline-flex btn-blue btn-round ">
              <span>{{ getCampaignButtonText() }}</span>
              <clip-loader class="ml-1" v-if="getCampaignLoaders.store" :size="size" :color="color"></clip-loader>
            </button>

          </template>

        </div>

      </div>
    </div>
  </div>

</template>

<script>

import {mapActions, mapGetters} from 'vuex'

export default ({

  data() {
    return {

      size: '14px',
      color: '#fff',
      ruleCheck: 'time'

    }
  },
  created() {
    if (!this.getCampaignAdd.brand_id) {
      this.$router.push({name: 'create'})
    }
  },

  computed: {

    ...mapGetters([
      'getCampaignAdd',
      'getCampaignLoaders'
    ])

  },

  methods: {
    ...mapActions([]),
    async handleTriggersCampaign() {
      // mixin method
      this.storeCampaign()
    }
  },
  watch: {
    'getCampaignAdd.trigger_seconds' (value) {

      if(this.isBridgeCTACampaign() && value <= 0) {
        this.getCampaignAdd.trigger_seconds = 7
        return
      }

      if(this.isBridgeCTACampaign() && this.getCampaignAdd.trigger_seconds <= 7) {
        this.getCampaignAdd.trigger_seconds = 7
      }

      if(this.isBridgeCTACampaign() && this.getCampaignAdd.trigger_seconds >= 30) {
        this.getCampaignAdd.trigger_seconds = 30
      }

      if(value <= 1) {
        this.getCampaignAdd.trigger_seconds = 1
        return
      }
    }
  }

})
</script>
